require.config({
  useMinified: true,
  baseUrl: getBaseUrl() + '/Scripts/',
  paths: {
    'formulaire-concours-vacances': 'ia/app/Pages-promotionnelles/concours-iaah-vacances/formulaire-concours-vacances',
    'formulaire-soumission-ass-vie': 'ia/app/Pages-promotionnelles/lp-assurance-vie/formulaire-soumission-ass-vie',
    'formulaire-soumission-lp-mra': 'ia/app/Pages-promotionnelles/mra/formulaire-soumission-mra',
    'formulaire-soumission-mra': 'mra/app/formulaire-soumission-mra',
    'formulaire-renseignements-mra': 'mra/app/formulaire-renseignements-mra',
    'formulaire-envoyer-courriel': 'ia/app/Formulaires/formulaire-envoyer-courriel',
    'formulaire-demande-dons': 'ia/app/Formulaires/DemandeDons/formulaire-demande-dons'
  },
  shim: {
    'formulaire-concours-vacances': ['jquery'],
    'formulaire-soumission-ass-vie': ['jquery'],
    'formulaire-soumission-mra': ['jquery'],
    'formulaire-renseignements-mra': ['jquery']
  },
  waitSeconds: '99'
});
function getBaseUrl() {
  var currentUrl = getCurrentScriptUrl() || '';
  // eslint-disable-next-line no-useless-escape
  return currentUrl ? currentUrl.replace(/^(.*\/\/[^\/?#]*).*$/, '$1') : '';
}
function getCurrentScriptUrl() {
  var configScriptTag = document.getElementById('main-require-config-script');
  if (configScriptTag) return configScriptTag.src;
}